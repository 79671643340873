.login{
    background-color: #013F2E;
    height: 100vh;
    width: 100%;
}

.adminsidebar {
    height: 100vh;
    width: 200px;
    position: relative;
    transition: width 0.3s ease-in-out;
    flex-shrink: 0; /* Prevents sidebar from shrinking */
}
.adminsidebar ul{
    height: 420px;
    overflow: auto;
}
.adminsidebar .sidebar_logo{
    height: 60px;
    padding: 10px;
    text-align: center;
}
.adminsidebar li.dropdown{
    margin: 0px !important;
    padding: 0px !important;
}
.adminsidebar ul.dropdown_menu {
    max-height: 0;
    overflow: hidden;
    margin: 0px !important;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: max-height 0.8s ease;
}

.adminsidebar ul.dropdown_menu.open {
    max-height: 500px; 
}
.nav_icon{
    width: 16px;
    height: 16px;
    margin: 0px 8px;
}
.dropdown_icon{
    width: 12px;
    height: 12px;
    margin-top: 4px;
}
hr{
    color: #acaaaa;
}
.active {
    width: 60px;
    transition: width 0.3s ease-in-out;
}

.adminsidebar ul {
    padding: 10px;
    margin: 10px 0px;
}

.adminsidebar ul li {
    display: list-item;
    list-style: none;
    white-space: nowrap;
    margin: 2px 0px;
    border-bottom: 1px solid #474646;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
}
.adminsidebar ul li a{
    text-decoration: none;
    padding: 10px 0px;
    width: 100%;
    display: block;
    color: #ccc;
    font-size: 13px;
}
.adminsidebar ul li a:hover{
    color: #E34D50;
}
.active ul li {
    opacity: 0; /* Hide text in collapsed state */
}

.collapse {
    position: absolute;
    bottom: 40px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.collapse button {
    background: none;
    border: none;
    color: white;
    font-size: 1.2em;
}

.collapse button:hover {
    color: #ccc;
}
.contentarea {
    flex-grow: 1; 
    height: 93vh;
    overflow-y: auto;
}

label{
    display: block;
}
.formInput, .formTextarea, .formSelect{
    display: block;
    width: 100%;
    border: 1.5px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
.formInput:focus, .formTextarea:focus, .formSelect:focus, .formInputSelect input:focus, .formInputSelect select:focus{
    border: 1.5px solid #E34D50;
    outline: none;
}
.formInputSelect input{
    width: 30%;
    height: 35px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
    text-align: center;
}
.formInputSelect{
    width: 100%;
}
.formInputSelect select{
    width: 65%;
    height: 35px;
    border: 1px solid #ccc;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.add_button, .submit_button, .cancel_button, .delete_button, .edit_button{
    padding: 8px;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    border: none;
    width: 70px;
    text-align: center;
    margin: 0px 5px;
    display: inline-block;
    text-decoration: none;
}
.add_button{
    background-color: rgb(47, 158, 47);
}
.submit_button{
    background-color: rgb(31, 121, 31);
}
.cancel_button{
    background-color: #E34D50;
}
.delete_button{
    background-color: #d1494d;
}
.edit_button{
    background-color: #F69F2F;
}
.icon_list ul li, .icon_list ul li input, .icon_list ul li label {
    display: inline-block;
}
.icon_list ul li{
    width: 100px;
    margin: 8px auto;
}
.icon_list ul li .icon{
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: #013F2E;;
    color: whitesmoke;
    margin: 0px 8px;
    border-radius: 5px;
}
.table {
    border: 1px solid #d6d6d6;
    background-color: #dfdede;
    border-radius: 8px; 
    overflow: hidden;
    width: 100%;
    border-collapse: separate; 
}

.table thead {
    background-color: #e0e0e0;
}

.table th, .table td {
    padding: 6px;
    font-size: 14px;
}

.table thead th:first-child {
    border-top-left-radius: 8px; 
}

.table thead th:last-child {
    border-top-right-radius: 8px; 
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px; 
}
.table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.table tbody tr:nth-child(even) {
    background-color: #fff; 
}

table.categoryTable .icon{
    background-color: #88B98E;
    border: 2px solid #5e8363;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 3px;
    color: white;
}

.textEditor{
    height: 250px;
    margin-bottom: 60px;
}


.bg_1{
    background: rgb(170,190,7);
    background: linear-gradient(90deg, rgba(170,190,7,0.7679446778711485) 35%, rgba(9,107,14,0.8463760504201681) 100%);
}

.bg_2{
    background: rgb(7,134,190);
    background: linear-gradient(90deg, rgba(7,134,190,0.7679446778711485) 35%, rgba(9,17,107,0.8463760504201681) 100%);
}

.bg_3{
    background: rgb(223,62,8);
    background: linear-gradient(90deg, rgba(223,62,8,0.7679446778711485) 35%, rgba(255,0,74,0.8463760504201681) 100%);
}
.bg_4{
    background: rgb(9,121,54);
    background: linear-gradient(90deg, rgba(9,121,54,0.7679446778711485) 35%, rgba(25,217,169,0.8463760504201681) 100%);
}

.bg_5{
    background: rgb(99,9,121);
    background: linear-gradient(90deg, rgba(99,9,121,0.7679446778711485) 35%, rgba(230,82,219,0.8463760504201681) 100%);
}

.bg_6{
    background: rgb(230,41,127);
background: linear-gradient(90deg, rgba(230,41,127,0.7679446778711485) 35%, rgba(65,14,207,0.8463760504201681) 100%);
}

.bg_7{
    background: rgb(207,154,18);
    background: linear-gradient(90deg, rgba(207,154,18,0.7679446778711485) 35%, rgba(159,38,17,0.8463760504201681) 100%);
}

.bg_8{
    background: rgb(70,128,7);
    background: linear-gradient(90deg, rgba(70,128,7,0.7679446778711485) 35%, rgba(17,218,179,0.8463760504201681) 100%);
}

.quill {
    height: 130px;
    margin-bottom: 40px;
}