
body{
    margin: 0;
    padding: 0;
    font-family: 'Lato', Arial, sans-serif;
    font-display: swap;
    font-style: normal;
}
.content{
    padding: 0;
    margin: 70px 0px;
}
 .navigation {
     height: 55px;
     background: linear-gradient(45deg, #4199fe, #74b4fe);
}
 .brand {
     position: absolute;
     padding-left: 20px;
     float: left;
     line-height: 55px;
     text-transform: uppercase;
     font-size: 1.4em;
}
 .brand a, .brand a:visited {
     color: #ffffff;
     text-decoration: none;
}
 .navcontainer {
     max-width: 1000px;
     margin: 0 auto;
}
.navigation nav {
     float: right;
}
.navigation nav ul {
     list-style: none;
     margin: 0;
     padding: 0;
}
.navigation nav ul li {
     float: left;
     position: relative;
}
.navigation nav ul li a,.navigation nav ul li a:visited {
     display: block;
     padding: 0 20px;
     line-height: 55px;
     color: #fff;
     background: #262626 ;
     text-decoration: none;
}
.navigation nav ul li a{
     background: transparent;
     color: #FFF;
}
.navigation nav ul li a:hover, .navigation nav ul li a:visited:hover {
     background: #2581DC;
     color: #ffffff;
}
 .navbardropdown li a{
     background: #2581DC;
}
.navigation nav ul li a:not(:only-child):after,.navigation nav ul li a:visited:not(:only-child):after {
     padding-left: 4px;
     content: ' \025BE';
}
.navigation nav ul li ul li {
     min-width: 190px;
}
.navigation nav ul li ul li a {
     padding: 15px;
     line-height: 20px;
}
 .navbardropdown {
     position: absolute;
     display: none;
     z-index: 1;
     background: #fff;
     box-shadow: 0 0 35px 0 rgba(0,0,0,0.25);
}
 .navmobile {
     display: none;
     position: absolute;
     top: 0;
     right: 0;
     background: transparent;
     height: 55px;
     width: 70px;
}
 @media only screen and (max-width: 800px) {
     .navmobile {
         display: block;
    }
    .navigation nav {
         width: 100%;
         padding: 55px 0 15px;
    }
    .navigation nav ul {
         display: none;
    }
    .navigation nav ul li {
         float: none;
    }
    .navigation nav ul li a {
         padding: 15px;
         line-height: 20px;
         background: #262626;
    }
    .navigation nav ul li ul li a {
         padding-left: 30px;
    }
     .navbardropdown {
         position: static;
    }
 }
@media screen and (min-width:800px) {
     .navlist {
         display: block !important;
    }
}

#navbartoggle {
    position: absolute;
    left: 18px;
    top: 15px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
}
#navbartoggle span, #navbartoggle span:before, #navbartoggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 30px;
    background: #ffffff;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
}
#navbartoggle span:before {
    top: -10px;
}
#navbartoggle span:after {
    bottom: -10px;
}
#navbartoggle.active span {
    background-color: transparent;
}
#navbartoggle.active span:before, #navbartoggle.active span:after {
    top: 0;
}
#navbartoggle.active span:before {
    transform: rotate(45deg);
}
#navbartoggle.active span:after {
    transform: rotate(-45deg);
}
 
.navlist {
  display: none;
}
.navlist.open {
  display: block;
}
.navbardropdown {
  display: none;
}
.carousel img {
    width: 100%;
    height: auto; 
    display: block;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    max-height: 490px;
}
.carousel .caption{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: -110px;
    color: white;
    border: 1px solid rgb(240, 239, 239);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    transition: bottom ease 0.5s;
    z-index: 99;
}
.carousel .caption a.heading{
    text-decoration: none;
    color: white;
    transition: ease 0.4s;
    font-size: 30px;
    font-weight: 600;
}
.carousel .caption a:hover{
    color: #013F2E;
}
.carousel .caption p{
    display: -webkit-box; 
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
}
.carousel .caption .metaInfo ul{
    padding: 8px;
    margin: 0;
    background-color: whitesmoke;
}
.carousel .caption .metaInfo ul li{
    display: inline;
    margin: 5px 10px;
    color: rgb(32, 32, 32);
}
.carousel .caption .metaInfo ul li a{
    text-decoration: none;
    color: rgb(32, 32, 32);
}
.carousel .caption .metaInfo ul li .icon,
.carousel .caption .metaInfo ul li a .icon{
    color: #013F2E;
    margin: 0px 5px;
}
.carousel .caption .rating ul{
    padding: 0;
    margin: 10px auto;
}
.carousel .caption .rating ul li{
    display: inline;
    margin: 3px;
}
.carousel .caption .rating ul li .icon{
    color: goldenrod;
}
.carousel img:hover + .caption {
    bottom: 0;
}
.carousel .caption:hover{
    bottom: 0;
}
.navbardropdown.show {
  display: block;
}
.bgprimary{
    background-color: #013F2E !important;
}
.textprimary{
    color: #013F2E;
}
.bgsecondary{
    background-color: whitesmoke;
}
.categorySidebar ul.category_list {
    padding: 0;
    margin: 0 auto;
    display: flex; /* Use Flexbox for responsive layout */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
}
.categorySidebar .viewAll{
    padding: 5px;
    text-align: center;
}
.categorySidebar .viewAll a{
    text-decoration: none;
    color: whitesmoke;
    font-weight: 500;
    text-transform: uppercase;
}
.categorySidebar ul.category_list li {
    list-style: none;
    background-color: #013F2E;
    margin: 2px;
    padding: 10px; 
    text-align: center;
    transition: ease 0.5s;
    flex: 0 1 auto;
    min-width: 50px;
    max-width: calc(20% - 4px);
    box-sizing: border-box; 
    border-bottom: 2px solid #012e22;
}

.categorySidebar ul.category_list li a {
    text-decoration: none;
    color: whitesmoke;
}

.categorySidebar ul.category_list li a .icon {
    background-color: whitesmoke;
    color: #013F2E;
    padding: 8px;
    border-radius: 2px;
    width: 40px;
    height: 40px;
}
.categorySidebar ul.category_list li a div{
    margin: 0 !important;
}

.categorySidebar ul.category_list li a p {
    padding: 0;
    margin: 5px 0 0 0;
}

.categorySidebar ul.category_list li:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transform: scale(1.1); /* Use transform for scaling effect */
}


/* Media Queries for additional responsiveness */
@media (max-width: 768px) {
    .categorySidebar ul.category_list li {
        flex: 1 1 calc(10% - 4px); /* Adjust for tablet screens */
        max-width: calc(10% - 4px);
    }
}



.searchbar{
    position: relative;
}
.searchbar .searchResult{
    position: absolute;
    background-color: white;
    border: 1px solid #e9e7e7;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
    width: 100%;
    top: 45px;
    z-index: 1;
}
.searchbar .searchResult::-webkit-scrollbar {
    width: 8px; 
}

.searchbar .searchResult::-webkit-scrollbar-track {
    background: #f5f5f5;
}

.searchbar .searchResult::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 2px solid #f5f5f5;
}

.searchbar .searchResult::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.searchbar .searchResult h5{
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    padding: 10px;
}
.searchbar .searchResult ul{
    padding: 0;
    margin: 0;
}
.searchbar .searchResult ul li{
    padding: 10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    align-items: center;
    border-bottom: 1px solid #f7f6f6;
}
.searchbar .searchResult ul li .query {
    flex: 1; 
    padding-right: 10px;
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
}
.searchbar .searchResult ul li .query a{
    text-decoration: none;
    color: rgb(83, 83, 83);
    font-weight: bold;
    letter-spacing: 0.3px;
}
.searchbar .searchResult ul li .query a:hover{
    color: black;
}

.searchbar .searchResult ul li .info {
    flex: 0 0 auto; 
    padding-left: 10px;
    white-space: nowrap;
}
.searchbar .searchResult ul li .info a{
    text-decoration: none;
    background-color: #BB0018;
    color: white;
    border-radius: 8px;
    padding: 2px;
    font-weight: bold;
    font-size: 13px;
}
.searchbar input{
    width: 100%;
    border: 1px solid #f1efef;
    padding: 10px 15px;
}
.searchbar input:focus{
    outline: none;
    border: 1px solid #ccc;
}

.recipegrid_v1{
    position: relative;
} 
.recipegrid_v1 .image_container{
    position: relative;
    z-index: -111;
    display: block;
    width: 100%;
    height: 220px;
}
.recipegrid_v1 .recipe_image{
    display: block;
    width: 100%;
    object-fit: cover;
}
.recipegrid_v1 .image_container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    transition: background 0.3s ease;
  }

.recipegrid_v1 .category_name{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.3px;
}
.recipegrid_v1 .recipe_title{
    font-size: 22px;
    font-weight: 800;
}
.recipegrid_v1 .recipe_title a{
    text-decoration: none;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.recipegrid_v1 .recipe_description {
    color: whitesmoke;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recipegrid_v1 .star_rating ul{
    padding: 0;
    margin: 0 0 5px 0;
}
.recipegrid_v1 .star_rating ul li{
    list-style: none;
    display: inline;
}
.recipegrid_v1 .star_rating ul li.rating_count{
    color: whitesmoke;
    padding: 5px;
}

.recipegrid_v2{
    text-align: center;
    margin: 35px auto;
}
.recipegrid_v2 .recipe_image_container {
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}
.recipegrid_v2 .recipe_image_container img {
	transition: all 0.3s ease-in-out;
}

.recipegrid_v2 .recipe_image_container img:hover {
    -webkit-transform: rotate(5deg) scale(1.10);
	-ms-transform: rotate(5deg) scale(1.10);
	transform: rotate(5deg) scale(1.10);
}
.recipegrid_v2 .recipe_details .recipe_category a{
    text-decoration: none;
    color: #013F2E;
    padding: 8px;
}
.recipegrid_v2 .recipe_details h3{
    padding: 0;
    margin: 0;
    letter-spacing: 1.3px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.recipegrid_v2 .recipe_details h3 a{
    text-decoration: none;
    color: #4b4949;
    transition: ease 0.5s;
}
.recipegrid_v2 .recipe_details h3 a:hover{
    color: #013F2E;
}
.recipegrid_v2 .recipe_meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.recipegrid_v2 .recipe_meta span, 
.recipegrid_v2 .recipe_meta ul {
    display: flex;
    align-items: center;
}

.recipegrid_v2 .recipe_meta ul {
    margin: 0 auto;
    padding: 0;
    list-style: none; 
    display: flex; 
}

.recipegrid_v2 .recipe_meta ul li .icon{
    color: #9e9b9b;
}

.recipegrid_v2 .recipe_meta .icon {
    margin-right: 5px;
    font-size: 20px;
}



.recipegrid_v2 .recipe_details .recipe_meta ul{
    display: inline;
}
.recipegrid_v2 .recipe_details .recipe_meta ul li{
    display: inline;
}
.recipegrid_v2 .recipe_details .recipe_description{
    padding: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px;
}
.recipegrid_v2 .recipe_details .read_more{
    padding: 10px;
    text-decoration: none;
    color: white;
    background-color: #013F2E;
    border-radius: 5px;
}

.recipegrid_v2 ul.pagination{
    display: inline-flex;
    list-style: none;
}
.recipegrid_v2 ul.pagination li{
    padding: 2px 8px;
    margin: 1px;
    background-color: #f1efef;
    transition: ease 0.2s;
}
.recipegrid_v2 ul.pagination li:hover{
    background-color: #012e22;
    color: whitesmoke;
}
.recipegrid_v2 ul.pagination li.active{
    background-color: #012e22;
    color: whitesmoke;
    font-weight: bolder;
}

.recipegrid_v3 {
    width: 95%;
    margin: 10px auto;
}
.recipegrid_v3 h6{
    font-weight: 600;  
}
.recipegrid_v3 .recipe_image_container {
    position: relative;
    display: inline-block;
    overflow: hidden; 
}
  
.recipegrid_v3 .recipe_image_container img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover; 
}
  
.recipegrid_v3 .recipe_image_container::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 90%);
    z-index: 1; 
    pointer-events: none; 
}
  
  
.recipegrid_v3 .block {
    position: relative;
    overflow: hidden; 
    padding: 5px;
}

.recipegrid_v3 .recipe_image_container {
    position: relative;
}

.recipegrid_v3 .recipe_image_container img {
    width: 100%;
    border-radius: 5px;
}
  
.recipegrid_v3 .recipe_image_container::before {
    position: absolute;
    content: '';
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px; 
    z-index: 1;
}
  
.recipegrid_v3 .recipe_details {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: 2; 
    color: white;
}
  
.recipegrid_v3 .recipe_details h5 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}
  
.recipegrid_v3 .recipe_details p {
    font-size: 1rem;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.8);
}
  

.top_banner{
    padding: 10px;
    height: 220px;
    background-color: rgba(1, 63, 46, 0.90);
    text-align: center;
    margin-bottom: 15px;
    position: relative;
}
.banner_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;

}
.top_banner .banner_content h3{
    font-weight: bold;
    letter-spacing: 1.3px;
    color: whitesmoke;
    letter-spacing: 1.2px;
    font-size: 35px;
}
.recipe_section{
    width: 90%;
    margin: 10px auto;
}
.recipe_section .section_heading{
    margin: 20px 0px;
    padding-bottom: 5px; 
    position: relative;
    border-bottom: 1px solid #e2e2e2;
}
.recipe_section .section_heading::before{
    position: absolute;
    content: "";
    bottom: -2.5px;
    border-radius: 5px;
    left: 0;
    background-color: #013F2E;
    height: 5px;
    width: 80px;
}
.recipe_section .image_section{
    border-radius: 5px;
    position: relative;
}
.recipe_section .image_section img{
    height: 450px;
    width: 100%;
    object-fit: cover;
}
.recipe_section .image_section .recipe_meta ul{
    padding: 0;
    margin: 0 0 8px 0;
    display: flex;
    flex-wrap: wrap;
}
.recipe_section .image_section .recipe_meta ul li ul{
    display: inline;
}
.recipe_section .image_section .recipe_meta ul li{
    list-style: none;
    margin: 0px 10px;
}
.recipe_section .image_section .recipe_meta{
    text-align: center;
    cursor: pointer;
}
.recipe_section .image_section .recipe_meta .staricon, .staricon{
    color: rgb(253, 168, 11);
}
.recipe_section .image_section .recipe_meta .staricon:hover, .staricon:hover{
    color: rgb(252, 143, 0);
}
.recipe_section .image_section .recipe_meta .icon{
    height: 23px;
    width: 23px;
}
.recipe_section .image_section .recipe_meta button{
    background-color: transparent;
    border: none;
}
.recipe_section .image_section .recipe_meta .share_list{
    position: relative;
}
.recipe_section .image_section .recipe_meta .share_list ul {
    position: absolute;
    top: 35px;
    z-index: 9;
    background-color: #013F2E;
    left: 0;
    border-radius: 5px;
    padding: 0;
    margin: 0;
    width: 0;
    opacity: 0;
    transition: width 0.3s ease, opacity 0.3s ease;
}
  
.recipe_section .image_section .recipe_meta .share_list ul.show {
    width: 50px;
    opacity: 1;
}
.recipe_section .image_section .recipe_meta .share_list ul li{
    display: list-item;
    color: whitesmoke;
    border-bottom: 2px solid #013F2E;
    padding: 10px;
}
.recipe_section .image_section .recipe_meta .share_list ul li:hover{
    color: white;
    border-bottom: 2px solid rgb(255, 255, 255);
}
.recipe_section .image_section .recipe_meta .prep_time:hover,
.recipe_section .image_section .recipe_meta .cook_time:hover,
.recipe_section .image_section .recipe_meta .serving:hover,
.recipe_section .image_section .recipe_meta .serve_type:hover,
.recipe_section .image_section .recipe_meta button:hover{
    color: #013F2E;
}
.recipe_section .image_section .recipe_meta h5{
    font-weight: bolder;
    font-size: 25px;
    margin: 5px 0px;
}
.recipe_section .nutrition_section,
.recipe_section .ingredient_section{
    padding: 8px;
}
.recipe_section .nutrition_section{
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,0.17970938375350143) 0%, rgba(9,9,121,0.1825105042016807) 35%, rgba(0,212,255,0.196516106442577) 100%);
    border-radius: 5px;
}
.recipe_section .ingredient_section{
    background: rgb(29,0,36);
background: linear-gradient(90deg, rgba(29,0,36,0.17970938375350143) 0%, rgba(121,9,64,0.1825105042016807) 35%, rgba(255,0,52,0.196516106442577) 100%);
    border-radius: 5px;
}
.recipe_section .description_section,
.recipe_section .otherdetail_section{
    padding: 10px 5px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 35px;
}
.recipe_section .nutrition_section ul,
.recipe_section .ingredient_section ul{
    padding: 0;
    margin: 0;
}
.recipe_section .nutrition_section ul li,
.recipe_section .ingredient_section ul li{
    list-style: none;
    display: list-item;
    padding: 10px 0px;
}
.recipe_section .ingredient_section ul li{
    border-bottom: 1px solid #e0dddd;
}
.recipe_section .ingredient_section ul li input,
.recipe_section .ingredient_section ul li label{
    display: inline;
}
.recipe_section .nutrition_section ul li input,
.recipe_section .ingredient_section ul li input{
    margin-right: 10px;
}

.recipe_section .nutrition_section ul li{
    border-bottom: 1px dotted #ccc;
    display: flex;
    justify-content: space-between;
}
.recipe_section .direction_section ul{
    counter-reset: step-counter;
    list-style: none; 
    padding: 0;
    font-size: 18px;
    letter-spacing: 1px;
}
.recipe_section .direction_section ul li{
    counter-increment: step-counter; 
    display: flex; 
    align-items: flex-start;
    margin-bottom: 20px;
}
.recipe_section .direction_section ul li::before{
    content: "Step " counter(step-counter);
    font-weight: bold; 
    color: whitesmoke;
    font-size: 16px;
    flex-shrink: 0; 
    width: 80px; 
    display: inline-block; 
    background: #013F2E;
    margin-right: 10px;
    padding: 5px;
    width: 100px;
    text-align: center;
    border-radius: 2px;
    margin-top: 5px;
}
.recipe_section .otherdetail_section ul{
    counter-reset: step-counter;
    list-style: none; 
    padding: 0;
    font-size: 18px;
    letter-spacing: 1px;
}
.recipe_section .otherdetail_section ul li{
    counter-increment: step-counter; 
    display: flex; 
    align-items: flex-start;
    margin-bottom: 10px;
}
.recipe_section .otherdetail_section ul li:before{
    content: counter(step-counter);
    font-weight: bold; 
    color: whitesmoke;
    font-size: 16px;
    flex-shrink: 0; 
    width: 47px;
    height: 47px; 
    display: inline-block; 
    background: #013F2E;
    margin-right: 10px;
    padding: 5px;
    text-align: center;
    border-radius: 50%;
    margin-top: 5px;
}
.recipe_section .review_section input, .recipe_section .review_section textarea{
    width: 100%;
    padding: 10px;
    margin: 5px auto;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    border: 1px solid #ececec;
}
.recipe_section .review_section .post_button{
    min-width: 180px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    padding: 8px;
    border: none;
    border-radius: 5px;
}
.recipe_section .review_section .reviews .comment{
    margin: 15px auto;
    border-bottom: 1px solid rgb(231, 231, 231);
}
.recipe_section .review_section .reviews .comment small{
    font-size: 12px;
    color: gray;
}
.related_article img{
    width: 100%;
    object-fit: cover;
}
.newsletter_box{
    border-radius: 5px;
    padding: 25px 0px;
    text-align: center;
    background-color: #f7f4f4;
}
.newsletter_box .newsletter_input, .newsletter_box button{
    padding: 15px 10px;
    width: 85%;
    border-radius: 5px;
    display: block;
    margin: 5px auto;
}
.newsletter_box .newsletter_input{
    border: 1px solid #ccc;
    margin: 8px auto;
}
.newsletter_box button{
    background-color: #013F2E;
    color: white;
    border: none;
}
.categories_list{
    background-color: #f7f4f4;
    border-radius: 5px;
}
.categories_list .top{
    padding: 15px;
}
.categories_list .bottom{
    background-color: #e4e4e4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    text-align: center;
}
.categories_list .bottom a{
    text-decoration: none;
    font-weight: bold;
    color: #013F2E;
    transition: ease 0.6s;
}
.categories_list ul{
    padding: 0;
    margin: 0;
}
.categories_list ul li{
    list-style: none;
    padding: 10px;
}
.categories_list ul li{
    color: #013F2E;
}
.categories_list ul li a{
    text-decoration: none;
    padding: 10px;
    color: #222121;
    font-weight: 600;
    transition: ease 0.6s;
}
.categories_list ul li a:hover, .categories_list .bottom a:hover{
    letter-spacing: 1px;
    text-decoration: underline;
}
.categories_list ul li span{
    float: right;
    color: #4b4949;
    font-size: 14px;
}
.socialmedial_block a, .socialmedial_block_v2 a{
    text-decoration: none;
    color: #4b4949;
    font-weight: 500;
}
.socialmedial_block .block{
    border-radius: 3px;
    padding: 10px;
    background-color: #e4e4e4;
    transition: ease 0.4s;
}
.socialmedial_block .block:hover{
    background-color: #013F2E;
    color: white;
}
.socialmedial_block .block .icon{
    width: 18px;
    height: 18px;
    margin-right: 7px;
}
.socialmedial_block .block span{
    display: inline;
    text-transform: uppercase;
}

.socialmedial_block_v2 .fbblock,
.socialmedial_block_v2 .instablock,
.socialmedial_block_v2 .youtubeblock,
.socialmedial_block_v2 .twitterblock,
.socialmedial_block_v2 .pintrestblock,
.socialmedial_block_v2 .dribbleblock{
    text-align: center;
    color: white;
    padding: 8px;
    border-radius: 3px;
}
.socialmedial_block_v2 .fbblock{
    background-color: #3B5B95;
}
.socialmedial_block_v2 .instablock{
    background-color: #5656D6;
}
.socialmedial_block_v2 .youtubeblock{
    background-color: #FC0000;
}
.socialmedial_block_v2 .twitterblock{
    background-color: #22A4EF;
}
.socialmedial_block_v2 .pintrestblock{
    background-color: #BB0018;
}
.socialmedial_block_v2 .dribbleblock{
    background-color: #E84586;
}
.aboutme .profileImage{
    text-align: center;
}
.aboutme .profileImage img{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 10px auto;
}
.aboutme h4{
    text-align: center;
    margin: 5px auto;
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-size: 30px;
    font-weight: 700;
}
.aboutme p{
    text-align: center;
    letter-spacing: 1.2px;
    color: #949292;
}
.categories_page {
  position: relative;
}
.categories_page .categories_box {
    position: relative;
    overflow: hidden; 
}
.categories_page .categories_box .categories_image {
    position: relative;
    border-radius: 10px;
}
.categories_page .categories_box img {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;
}

.categories_page .categories_box:hover img {
   transform: scale(1.4); 
}
  
.categories_page .categories_box img {
    width: 100%;
    height: auto;
}
  
.categories_page .categories_box .categories_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 10px;
    border-radius: 5px;
}
.searchFilter{
    position: relative; 
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    background: rgba(243, 243, 243, 0.8); 
    border-radius: 5px;
}
.searchFilter label{
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}
.formSelect, .formInput, .formButton{
    width: 100%;
    display: block;
    padding: 12px 8px;
    border: 1px solid #e0dddd;
    border-radius: 5px;
    outline: none;
}
.footer{
    background-color: whitesmoke;
    padding-top: 40px;
}
.footer .website_details{
    padding: 20px;
    text-align: center;
}
.footer .website_details img{
    width: 160px;
    margin: 8px auto;
}
.footer .website_details p{
    color: #282929;
}
.footer h5{
    padding: 5px 0px;
}
.footer ul{
    padding: 0;
    margin: 0;
}
.footer ul li{
    list-style: none;
}
.footer ul li a{
    text-decoration: none;
    color: rgb(48, 47, 47);
    display: block;
    width: 100%;
    margin: 5px 0px;
    transition: ease 0.4s;
}
.footer ul li a:hover{
    color: black;
}
.footer ul li a .icon{
    color: #013F2E;
    margin-right: 5px;
}
.footer ul.social_icon{
    color: white;
}
.footer .social_icon .facebookicon{
    color: #0862F7;
}
.footer .social_icon .instagramicon{
    color: #F70F55;
}
.footer .social_icon .twittericon{
    color: #29A4D9;
}
.footer .social_icon .yticon{
    color: #F70000;
}
.footer .social_icon .pinteresticon{
    color: #DF0018;
}
.footer .social_icon .redditicon{
    color: #F74300;
}






.logo {
    color: black;
    display: inline-block;
}

.header {
    background-color: #fff;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}

.header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.header li a:hover,
.header .menu_btn:hover {
    background-color: #f4f4f4;
}

.header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
    overflow: hidden;
    text-align: center;
}
.header .menu .newsletter{
    padding-left: 20px;
}
.header .menu .newsletterBtn{
    padding: 10px 20px;
    text-align: center;
    border-radius: 8px;
    border: none;
    background-color: #013F2E;
    color: whitesmoke;
    margin: 12px auto;

}
.header .open {
    max-height: 300px;
    transition: max-height .5s ease-in;
}

/* menu icon */

.header .menu_icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.header .menu_icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu_icon .navicon:before,
.header .menu_icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu_icon .navicon:before {
    top: 5px;
}

.header .menu_icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu_btn {
    display: none;
}

.header .menu_btn:checked ~ .menu {
    max-height: 100vh;
    height: auto;
}

.header .menu_btn:checked ~ .menu_icon .navicon {
    background: transparent;
}

.header .menu_btn:checked ~ .menu_icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu_btn:checked ~ .menu_icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu_btn:checked ~ .menu_icon:not(.steps) .navicon:before,
.header .menu_btn:checked ~ .menu_icon:not(.steps) .navicon:after {
    top: 0;
}

/* Dropdown styles */
.dropdown ul {
    display: none;
    position: absolute;
    background-color: #fcfafa;
    list-style: none;
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
    z-index: 100;
}
.dropdown ul li a {
    padding: 10px 20px;
    display: block;
    white-space: nowrap;
}

.showDropdown {
    display: block !important;
}

/* Full-width menu for mobile */
@media (max-width: 48em) {
    .header ul {
        width: 100%;
    }

    .header .menu li {
        width: 100%;
    }

    .header li a {
        padding: 16px;
        text-align: center;
    }

    .dropdown ul {
        width: 100%;
        text-align: center;
        position: relative; /* Set position relative for dropdown items in mobile */
    }

    .dropdown ul li {
        position: relative; /* Set relative position for each dropdown list item */
    }

    .dropdown ul li a {
        padding: 12px 16px;
    }
}

/* Larger screen adjustments */
@media (min-width: 48em) {
    .header li {
        float: left;
    }

    .header li a {
        padding: 22px 14px;
    }

    .header .menu {
        clear: none;
        float: right;
        max-height: none;
    }

    .header .menu_icon {
        display: none;
    }

}

.aboutus {
    margin-top: 120px;
    margin-bottom: 30px;
    padding: 10px;
  }
  
  .aboutus .sidenav {
    position: sticky;
    top: 120px;
    padding: 10px;
    background-color: rgb(241, 241, 241);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .aboutus .sidenavCollapse{
    background-color: rgb(228, 227, 227);
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
  }
  .aboutus .sidenavCollapse button{
    width: 100%;
    text-align: center;
    background-color: transparent;
    padding: 5px;
    border: none;
  }
  .aboutus h2{
    font-weight: bold;
  }
  .aboutus p, .aboutus ul{
    letter-spacing: 1.2px;
    line-height: 30px;
    font-size: 18px;
  }
  .aboutus .sidenav ul {
    padding: 5px;
    margin: 0;
    position: relative;
    border-left: 1px solid rgb(192, 191, 191);
    transition: height 0.3s ease;
  }
  
  .aboutus .sidenav ul li {
    list-style: none;
  }
  
  .aboutus .sidenav ul li button {
    display: block;
    position: relative;
    width: 100%;
    text-decoration: none;
    padding: 10px;
    border-bottom: 1px solid transparent;
    color: #012e22;
    border-radius: 0px;
    text-align: left;
    background-color: transparent;
    outline: none;
  }
  .aboutus .sidenav ul li button:hover{
    border-bottom: 1px solid #012e22;
  }
  .aboutus .sidenav ul li button.active {
    font-weight: bolder;
  }
  .aboutus .sidenav ul li button.active::before{
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 7.5px 0 7.5px 10px; 
    border-color: transparent transparent transparent #012e22;
    left: -10px; 
    top: 50%;
    transform: translate(0, -50%);
    transition: transform 0.3s ease;
  }

  .aboutus .sidenav ul.expanded{
     height: auto !important;
     overflow: visible !important;
   }

  @media (max-width: 765px){
    .aboutus{
        margin-top: 0px;
    }
    .aboutus .sidenavCollapse{
        display: block;
    }
    .aboutus .sidenav ul{
        height: 50px;
        overflow: hidden;
    }
  }


.errorContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    align-items: center;
    justify-content: center; 
    background-color: #f0f0f0;
}

.errorContainer .error {
    text-align: center;
    padding: 30px 10px;
}
.errorContainer .error h1{
    font-size: 100px;
    font-weight: 900;
    letter-spacing: 3px;
    padding: 0;
    margin: 0
}
.errorContainer .error h5{
    font-size: 40px;
}
.errorContainer .error a{
    padding: 10px 20px;
    text-decoration: none;
    color: whitesmoke;
    background-color: #2581DC;
    border-radius: 10px;
}