.navbar{
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .nav-link{
    padding: 10px 20px !important;
    color: black;
    font-weight: 600;
    transition: ease 0.4s;
    font-size: 14px;
  }
  .nav-link:hover{
    background-color: whitesmoke;
  }
  
  .navbar-nav button{
    background-color: #053F2F;
    border: 2px solid transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: ease 0.3s;
  }
  .navbar-nav button:hover{
    background-color: whitesmoke;
    border-color: #053F2F;
    color: #053F2F;
  }
  
  .dropdown-toggle::after {
    content: ''; 
    border: solid #f35c5c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .dropdown-menu{
    border-radius: 2px;
  }